import { BRAND } from 'config/core';
import { getPageLink } from 'helpers/resources';

const adAndDConfig = {
  common: {
    brand: BRAND.metlife,
    type: 'AD',
    maxAge: 80,
    notAvailableForStates: [
      'AA',
      'AE',
      'AP',
      'AK',
      'DE',
      'ME',
      'MP',
      'MT',
      'OR',
      'WA',
      'WV'
    ],
    dutyStatuses: ['2', '1', 'C', '3', '7', 'N-', 'N', '8.1'],
    branches: ['2', '5', '6', '4', '3', '1', '8']
  },
  'ad-and-d': {
    shortName: 'AD&D',
    appLink: getPageLink('page-ad-and-d-app')
  },
  'ad-and-d-spouse': {
    shortName: 'AD&D for Sponsored Spouse',
    appLink: getPageLink('page-ad-and-d-spouse-app'),
    memberStatuses: ['N1', 'N-1', 'A3', 'C1']
  }
};

export default adAndDConfig;
