/*

  Files in DXP:
  https://firebrandlab.com/admin/assets?tags[]=mba-web

  Adding file:
  - The id must be unique
  - The id of the file must be the same as its name in DXP
  - The file must have the tag "mba-web" in DXP
  - Add "docs.militarybenefit.org" domain instead of DXP default

  Editing file:
  - File can be updated in DXP interface and if its name won't be changed
  its link won't be changed too.

  Removing file:
  - Remove file from DXP if it's not used in other projects
  - Remove only tag "mba-web" from the file in DXP if it's used in other projects

*/

const files = [
  {
    id: 'file-life-notes-2023',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/650db2143a66282b32e8c8ac/life_notes_2023.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/650db2143a66282b32e8c8ac',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-life-notes-2024',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/66bc719cfa2088ac2dd99bea/life_notes_2024.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/66bc719cfa2088ac2dd99bea',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-mba-protection-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/64d0f883f6855bf8705ded2b/mba_protection_brochure_opt.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/64d0f883f6855bf8705ded2b',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-will-prep-faq',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5e9057935d34eb001291e7e2/will_prep_faq_exp0618_unlocked.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5e9057935d34eb001291e7e2',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-ers-faq',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5ee9ee580e44680012cae071/ers_faq.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5ee9ee580e44680012cae071',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-eft-form',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eaff47fe2b2ff0011425254/eft_form.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5eaff47fe2b2ff0011425254',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-request-for-allotment',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eaff50ce2b2ff0011425256/request_for_allotment.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5eaff50ce2b2ff0011425256',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-credit-auth',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eaff3f8e2b2ff0011425252/creditauth.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5eaff3f8e2b2ff0011425252',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-electronic-consent-online',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f044d1512c63e001105bcd4/electronic_consent_online.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5f044d1512c63e001105bcd4',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-beneficiary-designation-form',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eaff444e2b2ff0011425253/beneficiary_designation_form.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5eaff444e2b2ff0011425253',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-metlaw-mba-enrollment-fillable-2024',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/66ea86ad07855df12ac5d2f2/metlaw_legal_services_enrollment_form.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/66ea86ad07855df12ac5d2f2',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-mba-22-annual-report',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/63ca71bf05d25600116942dd/6003_mba_annual_report_trifold_01112392.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/63ca71bf05d25600116942dd',
    dxpTags: ['mba-web']
  },

  /*
    PRIVACY
  */

  {
    id: 'file-mba-privacy-statement',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eb19488604c4c0011da8c74/mba_privacy_statement.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5eb19488604c4c0011da8c74',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-metlife-privacy-statement',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eb19528604c4c0011da8c75/metlife_privacy_statement.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5eb19528604c4c0011da8c75',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-mba-conflict-of-interest-policy',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eb197ff604c4c0011da8c77/mba_conflict_of_interest_policy.docx',
    dxp: 'https://firebrandlab.com/admin/assets/5eb197ff604c4c0011da8c77',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-mba-terms-of-service',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f48c5bebc685200124c5d45/mba_terms_of_service.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5f48c5bebc685200124c5d45',
    dxpTags: ['mba-web']
  },

  /*
    HARTFORD
  */

  // TODO: delete?
  // {
  //   id: 'file-hartford-private-policy',
  //   link:
  //     'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eb1957177ec42001015af60/hartford_private_policy.pdf',
  //   dxp: 'https://firebrandlab.com/admin/assets/5eb1957177ec42001015af60',
  //   dxpTags: ['mba-web']
  // },
  {
    id: 'file-hipaa-notice-of-privacy-practices',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/64392ec13ee3b500124110b5/hipaa_notice_of_privacy_practices_2022.docx',
    dxp: 'https://firebrandlab.com/admin/assets/64392ec13ee3b500124110b5',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-medicare-replacement-notice',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5ebad73477b87900105b43eb/medicare_replacement_notice.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5ebad73477b87900105b43eb',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-the-hartford-customer-privacy-notice',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f48c798bc685200124c5d46/the_hartford_customer_privacy_notice.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5f48c798bc685200124c5d46',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-medicare-notice',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f36a8c971691c001201be1e/medicare_notice.docx',
    dxp: 'https://firebrandlab.com/admin/assets/5f36a8c971691c001201be1e',
    dxpTags: ['mba-web']
  },

  /*
    HOSPITAL APP BROCHURES
  */

  {
    id: 'file-mba-cw-under-age-65-hip-pod-enrollment-form',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/63dab190340fbd00122c1dad/mba_cw_under_age_65_hip_pod_enrollment_form_0223.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/63dab190340fbd00122c1dad',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-mba-cw-over-age-65-hip-pod-enrollment-form',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/63dab0b9340fbd00122c1da2/mba_cw_over_age_65_hip_pod_enrollment_form_0223.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/63dab0b9340fbd00122c1da2',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-mba-ny-under-age-65-hip-pod-enrollment-form',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/641a10be3a74f40012fd95c8/mba_ny_under_age_65_hip_pod_enrollment_form_0223.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/641a10be3a74f40012fd95c8',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-mba-ny-over-age-65-hip-pod-enrollment-form',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/63dab0eea73c1900110b568a/mba_ny_over_age_65_hip_pod_enrollment_form_0223.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/63dab0eea73c1900110b568a',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-hip-claim',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5ebad4705441a30010ebf4e3/hip_claim.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5ebad4705441a30010ebf4e3',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-hip-claim-form-aps',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5ebad49b5441a30010ebf4e4/hip_claim_form_aps.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5ebad49b5441a30010ebf4e4',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-hipodubr-1-mba-cw-u65-insert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/63dab120340fbd00122c1da5/hipodubr_1_mba_cw_u65_insert_0223.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/63dab120340fbd00122c1da5',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-hipodobr-2-mba-cw-o65-insert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/63dab034a73c1900110b5683/hipodobr_2_mba_cw_o65_insert_0223.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/63dab034a73c1900110b5683',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-hipodubr-3-mba-ny-u65-insert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/63dab146340fbd00122c1da8/hipodubr_3_mba_ny_u65_insert_0223.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/63dab146340fbd00122c1da8',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-hipodobr-4-mba-ny-o65-insert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/63dab077a73c1900110b5684/hipodobr_4_mba_ny_o65_insert_0223.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/63dab077a73c1900110b5684',
    dxpTags: ['mba-web']
  },

  /*
    APPLICATION BROCHURES
  */

  {
    id: 'file-decreasing-group-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eba685d77b87900105b43b7/decreasing_group.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5eba685d77b87900105b43b7',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-decreasing-group-spouse-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eba825777b87900105b43b8/decreasing_group_spouse.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5eba825777b87900105b43b8',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-level-term-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5ebaa7f977b87900105b43be/level_term.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5ebaa7f977b87900105b43be',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-level-term-spouse-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f43a9c4bc685200124c5b58/mba_spnsrd_spouse_level_term_1020yr_3_28a_final_exp0321.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5f43a9c4bc685200124c5b58',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-federal-civilian-level-term-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f43ab2446a27400121e2bbf/mba_civilian_life_1020yrlevel_term_3_28a_final_exp0321.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5f43ab2446a27400121e2bbf',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-federal-civilian-level-term-spouse-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f43ab9746a27400121e2bc0/mba_civilian_life_spouse_1020yr_3_28a_final_exp0321.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5f43ab9746a27400121e2bc0',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-metlaw-legal-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f43ac2d46a27400121e2bc1/metlaw_certificate_of_coverage_mba.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5f43ac2d46a27400121e2bc1',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-nextgen-adult-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5e6f5be756901f0012894ba9/nextgen_adult.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5e6f5be756901f0012894ba9',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-nextgen-child-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5eba972877b87900105b43b9/nextgen_child.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5eba972877b87900105b43b9',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-term-90-plus-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5e6f5af5defb4e0012dfd7f4/term_90_plus.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5e6f5af5defb4e0012dfd7f4',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-term-90-plus-spouse-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5e6f5bbd56901f0012894ba8/term_90_plus_spouse.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5e6f5bbd56901f0012894ba8',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-federal-civilian-term-90-plus-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5e6f5c1056901f0012894baa/federal_civilian_term_90_plus.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5e6f5c1056901f0012894baa',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-federal-civilian-term-90-plus-spouse-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5e6f5c3456901f0012894bab/federal_civilian_term_90_plus_spouse.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5e6f5c3456901f0012894bab',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-term-90-plus-quick-step-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f43afa6bc685200124c5b5e/2018_11_13_mba_quick_step_term_90_plus.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5f43afa6bc685200124c5b5e',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-federal-civilian-term-90-plus-quick-step-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/5f43aff1bc685200124c5b5f/2018_11_13_mba_quick_step_term_90_plus_us_govt_civilian_am_ss.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/5f43aff1bc685200124c5b5f',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-tricare-brochure',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/63ca6fe605d25600116942dc/mba_hartford_tricare_supplement_brochure_0123.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/63ca6fe605d25600116942dc',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-tricare-brochure-mt',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6413a2583a74f40012fd8aee/mba_hartford_tricare_supplement_brochure_0123_mt.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6413a2583a74f40012fd8aee',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-tricare-brochure-ny',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6413a2833a74f40012fd8aef/mba_hartford_tricare_supplement_brochure_0123_ny.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6413a2833a74f40012fd8aef',
    dxpTags: ['mba-web']
  },

  /*
    NATIONAL GUARD CERTIFICATES
  */

  {
    id: 'file-national-guard-certificate',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6061a0e9f723080012f0c54b/national_guard_certificate.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6061a0e9f723080012f0c54b',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-national-guard-certificate-maine',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6061a12af723080012f0c54c/national_guard_certificate_maine.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6061a12af723080012f0c54c',
    dxpTags: ['mba-web']
  },

  /*
    CERTIFICATES
  */

  // SHARED

  {
    id: 'file-cert-nw-child-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6127600fa5c9d30013ebb435/nw_child_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6127600fa5c9d30013ebb435',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nw-veteran-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61276087a5c9d30013ebb436/nw_veteran_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61276087a5c9d30013ebb436',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nw-family-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6273c16e703e8f001335992d/nw_family_plan_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6273c16e703e8f001335992d',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-ak-child-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/612760d3a5c9d30013ebb437/ak_child_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/612760d3a5c9d30013ebb437',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-ak-family-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6273c6a2703e8f0013359944/ak_family_plan_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6273c6a2703e8f0013359944',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-ca-proof-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6127610777d0f500133ec4e1/ca_proof_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6127610777d0f500133ec4e1',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-gu-pow-mia-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6127617fa5c9d30013ebb438/gu_pow_mia_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6127617fa5c9d30013ebb438',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-id-child-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/612761bba5c9d30013ebb439/id_child_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/612761bba5c9d30013ebb439',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-id-veteran-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/612761e877d0f500133ec4e2/id_veteran_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/612761e877d0f500133ec4e2',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-la-child-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6127624fa5c9d30013ebb43a/la_child_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6127624fa5c9d30013ebb43a',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-la-veteran-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6127627e77d0f500133ec4e4/la_veteran_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6127627e77d0f500133ec4e4',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-la-family-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6273fee0353e8600123b0ba0/la_family_plan_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6273fee0353e8600123b0ba0',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-md-veteran-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/612762aca5c9d30013ebb43b/md_veteran_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/612762aca5c9d30013ebb43b',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-md-family-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6273ff74353e8600123b0ba6/md_family_plan_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6273ff74353e8600123b0ba6',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nm-child-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/612762e0a5c9d30013ebb43c/nm_child_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/612762e0a5c9d30013ebb43c',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-ny-child-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61276319a5c9d30013ebb43d/ny_child_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61276319a5c9d30013ebb43d',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-ny-veteran-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6127638ba5c9d30013ebb43e/ny_veteran_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6127638ba5c9d30013ebb43e',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-ny-family-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6274042d703e8f0013359acc/ny_family_plan_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6274042d703e8f0013359acc',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-pr-pow-mia-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/612763f077d0f500133ec4e5/pr_pow_mia_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/612763f077d0f500133ec4e5',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-mt-child-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61f2d814a960540014f7cc26/mt_child_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61f2d814a960540014f7cc26',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-mt-veteran-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/620149a6b2320300134715ef/mt_veteran_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/620149a6b2320300134715ef',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-tx-child-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6127663ba5c9d30013ebb43f/tx_child_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6127663ba5c9d30013ebb43f',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-tx-veteran-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/612766db77d0f500133ec4e6/tx_veteran_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/612766db77d0f500133ec4e6',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-tx-family-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62740497353e8600123b0bcb/tx_family_plan_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62740497353e8600123b0bcb',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-ut-veteran-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61276710a5c9d30013ebb440/ut_veteran_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61276710a5c9d30013ebb440',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-wa-child-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61276b2e77d0f500133ec4eb/wa_child_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61276b2e77d0f500133ec4eb',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-wa-veteran-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61276c6977d0f500133ec4ec/wa_veteran_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61276c6977d0f500133ec4ec',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-wa-disclosure-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdc4552d6400014af531c/wa_disclosure_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdc4552d6400014af531c',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-wa-family-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/627406e5703e8f0013359ae8/wa_family_plan_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/627406e5703e8f0013359ae8',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nw-how-we-pay-1-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61276fd577d0f500133ec4ed/nw_how_we_pay_rider_1.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61276fd577d0f500133ec4ed',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-how-we-pay-2-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6203975ab232030013471b20/how_we_pay_rider_2.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6203975ab232030013471b20',
    dxpTags: ['mba-web']
  },

  // TERM 90 PLUS CERTIFICATES

  {
    id: 'file-cert-term-90-plus-nw-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f4fa6fe4f72001295cbb8/nw_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f4fa6fe4f72001295cbb8',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-ak-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f4e2ffe4f72001295cb9e/ak_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f4e2ffe4f72001295cb9e',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-ks-term90-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6127621777d0f500133ec4e3/ks_term_90_plus_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6127621777d0f500133ec4e3',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-la-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f4ea85110280014795da1/la_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f4ea85110280014795da1',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-md-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f4f1cfe4f72001295cbae/md_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f4f1cfe4f72001295cbae',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-mt-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61ee8776a960540014f7c1b6/mt_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61ee8776a960540014f7c1b6',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-nc-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f4f4bfe4f72001295cbb1/nc_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f4f4bfe4f72001295cbb1',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-nh-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f4f715110280014795dae/nh_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f4f715110280014795dae',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-ny-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f4fdf5110280014795db6/ny_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f4fdf5110280014795db6',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-tx-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f5008fe4f72001295cbb9/tx_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f5008fe4f72001295cbb9',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-ut-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f502ffe4f72001295cbba/ut_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f502ffe4f72001295cbba',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-wa-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/613f5069fe4f72001295cbbb/wa_term_90_plus_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/613f5069fe4f72001295cbbb',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-rates-tob',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6127716777d0f500133ec4ee/term_90_plus_rates_tob.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6127716777d0f500133ec4ee',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-plus-rates-no-tob',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/612773b1a5c9d30013ebb448/term_90_plus_rates_no_tob.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/612773b1a5c9d30013ebb448',
    dxpTags: ['mba-web']
  },

  // LEVEL TERM CERTIFICATES

  {
    id: 'file-cert-level-term-10-nw-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdc8152d6400014af531d/nw_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdc8152d6400014af531d',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-nw-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdcb21ac6c20014843554/nw_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdcb21ac6c20014843554',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-ak-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6203996db232030013471b21/ak_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6203996db232030013471b21',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-ak-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdcec52d6400014af5323/ak_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdcec52d6400014af5323',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-ks-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdd1652d6400014af5324/ks_level_term_10_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdd1652d6400014af5324',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-ks-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdd5252d6400014af5326/ks_level_term_20_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdd5252d6400014af5326',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-la-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdd8b52d6400014af532d/la_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdd8b52d6400014af532d',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-la-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bddc51ac6c20014843565/la_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bddc51ac6c20014843565',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-md-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bddf052d6400014af5332/md_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bddf052d6400014af5332',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-md-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bde2452d6400014af5335/md_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bde2452d6400014af5335',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-mt-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61ee86fd79728900132376ac/mt_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61ee86fd79728900132376ac',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-mt-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/61ee8740a960540014f7c1b5/mt_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/61ee8740a960540014f7c1b5',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-nh-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bde6d52d6400014af5338/nh_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bde6d52d6400014af5338',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-nh-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bde9652d6400014af5339/nh_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bde9652d6400014af5339',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-nm-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdecc1ac6c20014843570/nm_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdecc1ac6c20014843570',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-nm-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdf051ac6c20014843575/nm_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdf051ac6c20014843575',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-nc-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdf2e1ac6c20014843578/nc_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdf2e1ac6c20014843578',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-nc-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdf591ac6c2001484357d/nc_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdf591ac6c2001484357d',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-tx-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdf7f1ac6c20014843580/tx_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdf7f1ac6c20014843580',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-tx-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdfa31ac6c20014843583/tx_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdfa31ac6c20014843583',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-ut-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618bdfc952d6400014af5348/ut_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618bdfc952d6400014af5348',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-ut-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618be02e52d6400014af534d/ut_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618be02e52d6400014af534d',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-10-wa-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618be05552d6400014af534e/wa_level_term_10_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618be05552d6400014af534e',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-20-wa-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/618be0961ac6c2001484358d/wa_level_term_20_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/618be0961ac6c2001484358d',
    dxpTags: ['mba-web']
  },

  // NEXGEN CHILD CERTIFICATES

  {
    id: 'file-cert-nexgen-child-nw-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345e02019bcb00146b0963/nw_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345e02019bcb00146b0963',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-ak-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345e33019bcb00146b0964/ak_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345e33019bcb00146b0964',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-la-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345e61019bcb00146b0965/la_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345e61019bcb00146b0965',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-md-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345e90019bcb00146b0966/md_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345e90019bcb00146b0966',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-mt-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345ebc019bcb00146b0967/mt_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345ebc019bcb00146b0967',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-nc-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345ee95197570012c78a39/nc_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345ee95197570012c78a39',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-nh-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345f125197570012c78a3a/nh_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345f125197570012c78a3a',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-ny-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345f3b5197570012c78a3b/ny_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345f3b5197570012c78a3b',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-tx-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345f625197570012c78a3c/tx_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345f625197570012c78a3c',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-ut-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345f885197570012c78a3d/ut_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345f885197570012c78a3d',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-child-wa-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62345fb45197570012c78a3f/wa_nexgen_child_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62345fb45197570012c78a3f',
    dxpTags: ['mba-web']
  },

  // NEXGEN ADULT CERTIFICATES

  {
    id: 'file-cert-nexgen-adult-nw-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62458ecf49f0d600118a8de7/nw_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62458ecf49f0d600118a8de7',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-ak-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62459bc749f0d600118a8e01/ak_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62459bc749f0d600118a8e01',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-ks-abo-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62458f0549f0d600118a8dea/ks_nexgen_adult_abo_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62458f0549f0d600118a8dea',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-la-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62458f2b49f0d600118a8deb/la_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62458f2b49f0d600118a8deb',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-md-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62458f5549f0d600118a8dec/md_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62458f5549f0d600118a8dec',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-mt-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62458f7f838275001455de8a/mt_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62458f7f838275001455de8a',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-nc-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62458fb3838275001455de8b/nc_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62458fb3838275001455de8b',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-nh-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62458fd7838275001455de8c/nh_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62458fd7838275001455de8c',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-ny-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6245900a838275001455de8d/ny_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6245900a838275001455de8d',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-tx-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6245903049f0d600118a8dee/tx_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6245903049f0d600118a8dee',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-ut-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6245906649f0d600118a8def/ut_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6245906649f0d600118a8def',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-wa-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62459091838275001455de8e/wa_nexgen_adult_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62459091838275001455de8e',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-nexgen-adult-rates',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/624590b6838275001455de8f/nexgen_adult_rates.xlsx',
    dxp: 'https://firebrandlab.com/admin/assets/624590b6838275001455de8f',
    dxpTags: ['mba-web']
  },

  // LEVEL TERM L1/L2 CERTIFICATES

  {
    id: 'file-cert-level-term-l1-l2-nw-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ee9d8cb856a0012ba2d1e/nw_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ee9d8cb856a0012ba2d1e',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-nw-contribution-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625eeaaacb856a0012ba2d23/nw_level_term_l1_l2_contribution_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625eeaaacb856a0012ba2d23',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-l1-rates',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625eead7cb856a0012ba2d26/level_term_l1_rates.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625eead7cb856a0012ba2d26',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-l2-rates',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625eeb003479370013459d84/level_term_l2_rates.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625eeb003479370013459d84',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-ak-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625eeb4acb856a0012ba2d2b/ak_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625eeb4acb856a0012ba2d2b',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-ks-abo-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625eed6c3479370013459d8e/ks_level_term_l1_l2_abo_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625eed6c3479370013459d8e',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-la-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625eeda73479370013459d8f/la_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625eeda73479370013459d8f',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-la-contribution-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625eedfd3479370013459d90/la_level_term_l1_l2_contribution_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625eedfd3479370013459d90',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-md-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625eef803479370013459d97/md_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625eef803479370013459d97',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-md-contribution-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625eefd43479370013459d9b/md_level_term_l1_l2_contribution_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625eefd43479370013459d9b',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-mt-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ef0053479370013459da0/mt_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ef0053479370013459da0',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-nc-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ef045cb856a0012ba2d37/nc_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ef045cb856a0012ba2d37',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-nh-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ef0713479370013459da1/nh_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ef0713479370013459da1',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-ny-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ef0a2cb856a0012ba2d42/ny_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ef0a2cb856a0012ba2d42',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-tx-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ef1153479370013459db3/tx_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ef1153479370013459db3',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-tx-contribution-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ef16d3479370013459db5/tx_level_term_l1_l2_contribution_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ef16d3479370013459db5',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-ut-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ef19b3479370013459dbc/ut_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ef19b3479370013459dbc',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-ut-contribution-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ef1cc3479370013459dbf/ut_level_term_l1_l2_contribution_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ef1cc3479370013459dbf',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-level-term-l1-l2-wa-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/625ef1fdcb856a0012ba2d5d/wa_level_term_l1_l2_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/625ef1fdcb856a0012ba2d5d',
    dxpTags: ['mba-web']
  },

  // TERM 90 (L3) CERTIFICATES

  {
    id: 'file-cert-term-90-l3-nw-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62740779353e8600123b0be4/nw_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62740779353e8600123b0be4',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-rates',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/627407ad703e8f0013359af1/term_90_l3_rates.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/627407ad703e8f0013359af1',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-ak-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6273c6f4353e8600123b0a75/ak_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6273c6f4353e8600123b0a75',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-ks-abo-rider',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6273fe12353e8600123b0b9b/ks_term_90_l3_abo_rider.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6273fe12353e8600123b0b9b',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-la-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6273fe7f703e8f0013359a93/la_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6273fe7f703e8f0013359a93',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-md-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62740184703e8f0013359ab1/md_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62740184703e8f0013359ab1',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-mt-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62740208353e8600123b0bb5/mt_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62740208353e8600123b0bb5',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-nc-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6274027a353e8600123b0bb8/nc_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6274027a353e8600123b0bb8',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-nh-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/627402c0353e8600123b0bbb/nh_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/627402c0353e8600123b0bbb',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-ny-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62740310353e8600123b0bbc/ny_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62740310353e8600123b0bbc',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-tx-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/627404ef353e8600123b0bcc/tx_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/627404ef353e8600123b0bcc',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-ut-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/62740572703e8f0013359ad9/ut_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/62740572703e8f0013359ad9',
    dxpTags: ['mba-web']
  },
  {
    id: 'file-cert-term-90-l3-wa-cert',
    link:
      'https://docs.militarybenefit.org/5a27c6ab97e6c034ef9fad27/assets/6274068e703e8f0013359ae7/wa_term_90_l3_cert.pdf',
    dxp: 'https://firebrandlab.com/admin/assets/6274068e703e8f0013359ae7',
    dxpTags: ['mba-web']
  }
];

export default files;
